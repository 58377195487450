import React, { FormEvent } from "react";
import SearchIcon from "./Icons/Search";
interface SearchProps {
  search: string;
  setSearch: Function;
}

const Search = ({ search, setSearch }: SearchProps) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <button>
        <SearchIcon />
      </button>
    </form>
  );
};

export default Search;
