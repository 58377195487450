import React from "react";
import Login from "./Login/Login";
import NewUser from "./NewUser/NewUser";

const Auth = () => (
  <>
    <header>
      <h1>Dev-Log</h1>
    </header>
    <main>
      <Login />
      <NewUser />
    </main>
  </>
);

export default Auth;
